import { Component, OnInit } from '@angular/core';
import moment from 'moment-timezone';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { GlobalService } from 'src/app/services/global/global.service';

// declare var moment: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgFor]
})
export class HomeComponent implements OnInit {
  active:any = 1;
  active_office:number = 0;
  office: any = [];
  showtimezone:boolean = false;
  constructor(
    public globalservice : GlobalService
    ) { }

  ngOnInit(): void {

    this.globalservice.setTitle('DASHBOARD');

    this.showtimezone = true;
    setTimeout(() => {
      this.setActive();
    }, 3000);

    this.office = [
      { name: 'Miami',tz:'EST5EDT', tz2:'-4',tz1: '-5', dst: true },
      { name: 'Freeport, BH',tz:'EST5EDT', tz2:'-4',tz1: '-5', dst: true},
      { name: 'Turku, Fl', tz:'EET', dst:true, tz1: '+2', tz2: '+3' },
      { name: 'Papenburg, GE', tz:'CET', tz2:'+2',tz1: '+1', dst: true },
      { name: 'St. Nazaire, FR', tz:'CET', tz2:'+2',tz1: '+1', dst: true },
      { name: 'Trieste, IT',  tz:'CET', tz2:'+2',tz1: '+1', dst: true  },
      { name: 'Ancona, IT',  tz:'CET', tz2:'+2',tz1: '+1', dst: true  },
      { name: 'Cadiz, SP',  tz:'CET', tz2:'+2',tz1: '+1', dst: true  },
      { name: 'Singapore', tz: 'Asia/Singapore' }
    ];

   
  
  }

  timeShow(data:any ={}){
    // global.moment();
    // console.log(moment().isDST());
    var time:any;
    var m = moment();
    if(data.dst){
      if(moment().tz(data.tz).isDST()){
       time = m.utcOffset(Number(data.tz2)).format('h:mm a');
      }else{
       time = m.utcOffset(Number(data.tz1)).format('h:mm a');
      }
    }else{
     time = m.tz(data.tz).format('h:mm a');
    //  console.log(data).format('h:mm a');
    }

    return time;

  }

  setActive(){

    this.active += 1;
    this.active_office += 1;
    if(this.active>4){
      this.active = 1;
    }

    if(this.active_office>8){
      this.active_office = 0;
    }

    setTimeout(() => {
      this.setActive();
    }, 3000);

  }

  




}
